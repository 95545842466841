import {PublicClientApplication} from "@azure/msal-browser"

const state = () => ({
  baseURL: process.env.VUE_APP_baseURL,
  userToken: JSON.parse(localStorage.getItem('userToken')) || '', // User token used for built in token auth
  loginStatus: JSON.parse(localStorage.getItem('is-logged-in')) || false,
  authType: JSON.parse(localStorage.getItem('authType')) || '',

  msalConfig: {
    auth: {
      clientId: 'd125c84d-cde6-46b7-a728-e0ea1d40f974',
      authority: 'https://login.microsoftonline.com/c157e8dd-af28-4797-9ebf-b5f35724f37c',
      redirectUri: process.env.VUE_APP_redirecturi,
      scopes: ['api://d125c84d-cde6-46b7-a728-e0ea1d40f974/read']
    },
    cache: {
      cacheLocation: 'localStorage'
    },
  },
})

const getters = {

  // Create header depending on what auth is used
  getAuthHeader: state => {
    if (state.accessToken) {
      return `Bearer ${state.accessToken}`
    } else if (state.userToken) {
      return `Token ${state.userToken}`
    } else return ''
  },

}

const actions = {

  async AzureAuthentication({ state, commit }) {
    let account
    const msalInstance = new PublicClientApplication(state.msalConfig);
    try {
      await msalInstance.handleRedirectPromise().then(resp => {
        if (resp !== null) {
          account = resp.account
        } else {
          const currentAccounts = msalInstance.getAllAccounts()
          if (!currentAccounts || currentAccounts.length < 1) {
            const loginRequest = { scopes: state.msalConfig.auth.scopes };
            msalInstance.loginRedirect(loginRequest)
          } else if (currentAccounts.length === 1) {
            account = currentAccounts[0]
          }
        }
      })

      if (account) {
        try {
          const tokenResponse = await msalInstance.acquireTokenSilent({ account: account, scopes: state.msalConfig.auth.scopes })
            commit('setAccessToken', tokenResponse.accessToken)
            commit('setAuthType', 'accessToken')
        } catch (error) {
          console.error('Error obtaining token, will try aquire with redirect', error)
          await msalInstance.acquireTokenRedirect({ scopes: state.msalConfig.auth.scopes })
        }
      }
    } catch (error) {
      console.error('Error obtaining token ',error)
    }
  },

  async checkAuth({ state, dispatch }) {
    if (state.authType === 'accessToken') {
      // will check for a new token or login
      await dispatch('AzureAuthentication')
      return true
    } else if (state.authType === 'userToken') {
      // using django auth userToken
      return true
    } else {
      return false
    }
  },

  signOutAzure({state}) {
    const msalInstance = new PublicClientApplication(state.msalConfig)
    msalInstance.logout()
  },

  // Backup auth, gets a user token from django
  async login({commit, state}, user) {
    const response = await fetch(state.baseURL + 'api-token-auth/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(user)
    })
    if (response.ok) {
      const data = await response.json()
      commit('setUserToken', data.token)
      commit('setAuthType', 'userToken')
      return true
    } else {
      return false
    }
  },

  logout({state, commit, dispatch}) {
    if (state.authType === 'accessToken') dispatch('signOutAzure');
    commit('setAccessToken', '')
    commit('setUserToken', '')
    commit('setAuthType', '')
  },
}

const mutations = {
  // User token for django built in auth
  setUserToken: (state, token) => {
    localStorage.setItem('userToken', JSON.stringify(token))
    state.token = token
  },

  setAccessToken(state, token){
    state.accessToken = token;
  },

  setAuthType(state, type){
    localStorage.setItem('authType', JSON.stringify(type))
    state.authType = type;
  },
  
}

export default {
  state,
  getters,
  actions,
  mutations
}