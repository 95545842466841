<template>
  <div class="box">
    <div class="columns is-centered">
      <div class="column">
        <h1 class="title has-text-centered">Printere</h1>
      </div>
    </div>
    <form @submit.prevent="onSubmit">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Navn</label>
            <div class="control">
              <input v-model="printer.name" class="input" type="text" required>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Addresse</label>
            <div class="control">
              <input v-model="printer.address" class="input" type="text" required>
            </div>
          </div>
        </div>
        <div class="column is-one-fifth">
          <div class="field">
            <label class="label">Port</label>
            <div class="control">
              <input v-model="printer.port" class="input" type="text" required>
            </div>
          </div>
        </div>
      </div>
        <div class="columns">
          <div class="column ">
            <div class="field">
              <label class="checkbox">
                <input v-model="printer.active" class="checkbox" type="checkbox">
                Aktiv?
              </label>
            </div>
          </div>
        </div>
        <button type="submit" class="button is-success">Legg til</button>
    </form>
    <div class="columns">
      <div class="column">
        <div class="table-container">
          <table class="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>id</th>
                <th>Navn</th>
                <th>Addresse</th>
                <th>Port</th>
                <th>Aktiv</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="printer in $store.state.printers.printers" :key="printer.id">
                <tr @mouseover="rowHover = printer.id" @mouseleave="rowHover = -1">
                  <td>{{ printer.id }}</td>
                  <td>{{ printer.name }}</td>
                  <td>{{ printer.address }}</td>
                  <td>{{ printer.port }}</td>
                  <td>
                    <span class="icon" :class="{'is-invisible' : !printer.active}"><i class="fas fa-check"></i></span>
                    <div class="is-pulled-right" :class="{'is-invisible' : rowHover !== printer.id}">
                      <span class="icon is-clickable mx-2" title="Set som aktiv printer" @click="onSetActive(printer)"><i class="fas fa-check-double"></i></span>
                      <span class="icon is-clickable" title="Slett"><i class="fas fa-trash-alt" @click="onDelete(printer.id)"></i></span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Printers',
  data() {return {
    rowHover: -1,
    printer: {
      name: '',
      address: '',
      port: '9100',
      active: true,
    }
  }},
  computed: mapGetters(['allPrinters']),
  methods: {
    ...mapActions(['getPrinters', 'addPrinter', 'updatePrinter', 'deletePrinter']),

    onSubmit() {
      this.addPrinter(this.printer)
        .then(() => this.getPrinters())
        .then(() => {
          this.printer = {
            name: '',
            address: '',
          }
        })
    },

    onSetActive(printer) {
      printer.active = true
      this.updatePrinter(printer)
        .then(() => this.getPrinters())
    },

    onDelete(id) {
      if (confirm('Vil du slette printer?')) {
        this.deletePrinter(id)
          .then(() => this.getPrinters())
      }
    },
    
  },
  async mounted() {
    this.getPrinters()
    
  }
}
</script>

<style>

</style>