<template>
  <div class="box">
    <div class="columns is-centered">
      <div class="column is-one-third is-flex">
          <div class="field has-addons is-pulled-left">
            <p class="control">
              <input class="input" type="text" placeholder="Søk" v-model="search" @keyup.enter="searchOrFilter()">
            </p>
            <p class="control">
              <button @click="searchOrFilter()" class="button is-success">
                Søk
              </button>
            </p>
          </div>
      </div>
      <div class="column is-flex is-justify-content-center">
        <h1 class="title has-text-centered">Transaksjoner</h1>
      </div>
      <div class="column is-one-third is-flex is-justify-content-flex-end">
          <button class="button is-link mx-1" @click="exportData(allTransactions)"><span class="icon"><i class="fas fa-file-export"></i></span><span>Exporter</span></button>
      </div>
    </div>

    <div class="columns">
      <div class="select">
        <select @change="searchOrFilter()" v-model="limit">
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="block">
          <progress v-if="isLoading" class="progress is-small is-primary" max="100"></progress>
          <table class="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>Dato, Klokkeslett</th>
                <th>Item</th>
                <th>Flyttet fra lokasjon</th>
                <th>Flyttet til lokasjon</th>
                <th>Endret neste lokasjon</th>
                <th>Bruker</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="transaction in allTransactions" :key="transaction.id">
                <tr>
                  <td>{{ new Date(transaction.dt).toLocaleString('no-NO') }}</td> 
                  <td><a :href="'/item/' + transaction.item">{{ transaction.item }}</a></td>
                  <td>{{ transaction.from_location }}</td>
                  <td>{{ transaction.to_location }}</td>
                  <td>{{ transaction.next_planned_location }}</td>
                  <td>{{ transaction.created_by }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="block">
          <nav class="pagination" role="navigation" aria-label="pagination">
            <button @click="page--; refreshData()" class="pagination-previous" title="Forrige side" :disabled="page == 1">Forrige</button>
            <button @click="page++; refreshData() " class="pagination-next" title="Neste side" :disabled="page == numPages">Neste</button>
            <ul class="pagination-list">
              <li v-for="n in pageList" :key="n">
                <button @click="page = n; refreshData()" class="pagination-link" :class="{ 'is-current': page == n }" aria-label="Page 1" aria-current="page">{{ n }}</button>
              </li>
              <li><span class="pagination-ellipsis">Antall: {{ $store.state.items.transactionCount }}</span></li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapGetters, mapActions } from 'vuex';

import XLSX from 'xlsx';


export default {
  name: 'TransactionList',
  components: {

  },

  data() {
    return {
      search: '',
      page: 1,
      limit: 50,
      selected: [],
      filter: {
        from_location: '',
        to_location: '',
        next_planned_location: '',
        created_by: '',
        item: '',
      },
      ordering: '-id',

      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['allTransactions']),

    numPages() {
      return Math.ceil(this.$store.state.items.transactionCount / this.limit)
    },

    pageList() {
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
      const fp = 1
      const lp = Math.ceil(this.$store.state.items.transactionCount / this.limit)
      const pl = range(Math.max(fp + 1, this.page - 4), Math.min(lp, Math.max(this.page + 4, 10)), 1)
      pl.unshift(fp)
      if (lp != pl[pl.length -1]) {pl.push(lp)}

      return pl
    },

    offset() {return (this.page-1) * this.limit},

  },

  methods: {
    ...mapActions(['getTransactions']),

    exportData(data) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'transactions');
      XLSX.writeFile(wb, 'out.xlsx');
    },

    async refreshData() {
      this.isLoading = true
      this.getTransactions({
        limit: this.limit,
        offset: this.offset,
        search: this.search,
        from_location: this.filter.from_location,
        next_planned_location: this.filter.next_planned_location,
        created_by: this.filter.created_by,
        item: this.filter.item,
        ordering: this.ordering,
        }).then(() => this.isLoading = false)
    },

    searchOrFilter() {
      // Reset page to 1 before refreshing data
      this.page = 1
      this.refreshData()
    },

  },
  async mounted() {
    this.refreshData()
  }
}
</script>

<style scoped>

</style>