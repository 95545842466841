const state = () => ({

})

const getters = {
  allItems: (state) => state.items,
  allTransactions: (state) => state.transactions,
}

const actions = {


  // Get items
  async getItems({ commit, dispatch, rootState, rootGetters }, params={}) {
    await dispatch('checkAuth')
    const url = new URL(rootState.common.baseURL + `api/items/`)
    url.search = new URLSearchParams(params)
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        Authorization: rootGetters.getAuthHeader // `Bearer ${rootState.common.token}`
      }
    });
    const data = await response.json()
    if (Array.isArray(data)) {
      // data is not paginated
      commit('setItems', data)
    } else {
      // data is paginated
      commit('setItems', data.results)
      commit('setItemCount', data.count)
    }
  },

  // Get single item
  async getItem({dispatch, rootState, rootGetters }, itemId) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + `api/items/${itemId}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: rootGetters.getAuthHeader
      }
    });
    return await response.json()
    // commit('setItem', await response.json());
  },

  // Add new item
  async addItem({rootState, dispatch, rootGetters}, item) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/items/', {
      method: 'POST',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    });
    return await response.json()
  },

  // Update single item
  async updateItem({dispatch, rootState, rootGetters}, item) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/items/' + item.id + "/", {
      method: 'PUT',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    });
    return await response.json()
  },

  // Delete single item
  async deleteItem({dispatch, rootState, rootGetters}, id) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/items/' + id, {
      method: 'DELETE',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      }
    });
    return response.ok
  },

  // Replace single item. This will return the new object
  async replaceItem({dispatch, rootState, rootGetters}, payload) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/items/' + payload.id + '/replace/', {
      method: 'POST',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload.options) // Set option if you want to print the item here
    });
    return await response.json()
  },


  // Print single item, supply the item id
  async printItem({dispatch, rootState, rootGetters}, itemId) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/items/' + itemId + "/", {
      method: 'PATCH',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({label_print: 'true'})
    });
    return await response.json()
  },

  // Move item by sending a transaction
  async moveItem({dispatch, rootState, rootGetters}, transaction) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/transactions/', {
      method: 'POST',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(transaction)
    });
    return response
  },

  // Get transactions
  async getTransactions({ commit, dispatch, rootState, rootGetters }, params={}) {
    await dispatch('checkAuth')
    const url = new URL(rootState.common.baseURL + `api/transactions/`)
    url.search = new URLSearchParams(params)
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        Authorization: rootGetters.getAuthHeader
      }
    });
    const data = await response.json()
    if (Array.isArray(data)) {
      // data is not paginated
      commit('setTransactions', data)
    } else {
      // data is paginated
      commit('setTransactions', data.results)
      commit('setTransactionCount', data.count)
    }
  },

  // Get transaction for an item
  async getItemTransactions({dispatch, rootState, rootGetters }, itemId) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + `api/items/${itemId}/transactions/`, {
      headers: {
        Authorization: rootGetters.getAuthHeader
      }
    });
    return await response.json()
    // commit('setItemTransactions', await response.json());
  },

}

const mutations = {
  setItems: (state, items) => {state.items = items},
  setItemCount: (state, count) => {state.itemCount = count},
  setTransactions: (state, transactions) => {state.transactions = transactions},
  setTransactionCount: (state, count) => {state.transactionCount = count},
}

export default {
  state,
  getters,
  actions,
  mutations
}