<template>
  <div class="main">
    <div class="container is-fluid">
      <Navbar />
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar,
  },
  data() {
    return {

    }
  },
  methods: {
  },
}
</script>

<style>
</style>
