const state = () => ({

})

const getters = {
  allPrinters: (state) => state.printers,
}

const actions = {
  async getPrinters({commit, dispatch, rootState, rootGetters}) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/printers/', {
      headers: {
        Authorization: rootGetters.getAuthHeader
      }
    });
    commit('setPrinters', await response.json());
  },

  async addPrinter({dispatch, rootState, rootGetters}, element) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/printers/', {
      method: 'POST',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(element)
    });
    return await response.json()
  },

  async updatePrinter({dispatch, rootState, rootGetters}, element) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/printers/' + element.id + '/', {
      method: 'PUT',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(element)
    });
    return await response.json()
  },

  async deletePrinter({dispatch, rootState, rootGetters}, id) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/printers/' + id, {
      method: 'DELETE',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      }
    });
    return response
  },

  async clearPrinterQue({dispatch, rootState, rootGetters}) {
    await dispatch('checkAuth')
    const response = await fetch(rootState.common.baseURL + 'api/printers/clear_que/', {
      method: 'POST',
      headers: {
        Authorization: rootGetters.getAuthHeader,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    });
    return response
  },

}

const mutations = {
  setPrinters: (state, printers) => {state.printers = printers}
}

export default {
  state,
  getters,
  actions,
  mutations
}