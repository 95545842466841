<template>
 <div>
   <div class="columns is-centered">
     <div class="column is-half">
      <div class="box">
       <div class="block is-flex is-justify-content-center">
          <button @click="$router.push('/authcallback')" class="button is-large is-info"><span class="icon"><i class="fab fa-microsoft"></i></span><span>Logg inn med Microsoft</span></button>
       </div>
        <form class="login" @submit.prevent="onSubmit">
          <h1 class="title">Logg inn</h1>
          <div class="field">
            <label class="label">Brukernavn</label>
            <div class="control">
              <input class="input" required v-model="username" type="text" placeholder="Brukernavn"/>
            </div>
          </div>
          <div class="field">
            <label class="label">Passord</label>
            <div class="control">
              <input class="input" required v-model="password" type="password" placeholder="Passord"/>
            </div>
          </div>
          <button class="button is-success" type="submit">Logg in</button>
        </form>
      </div>
     </div>
   </div>
 </div>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      account: undefined,
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(['login']),

    async onSubmit() {
      const loggedIn = await this.login({username: this.username, password: this.password})
      if (loggedIn) {
        this.$router.push('/')
      } else {
        console.error('Did not login')
      }
    },
  },

}
</script>

<style>

</style>