<template>
  <div>
    <MoveItems />
  </div>
</template>

<script>
import MoveItems from '../components/MoveItems.vue'
export default {
  name: 'MoveView',
    components: {
      MoveItems
  },
}
</script>