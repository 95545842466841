<template>
  <div class="box">
    <div class="level">
      <div class="level-item">
        <h1 class="title">Flytt</h1>
      </div>
    </div>
    <form>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Flytt til lokasjon</label>
            <div class="control">
              <input type="text" class="input" v-model="to_location">
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Neste planlagt lokasjon</label>
            <div class="control">
              <input type="text" class="input" v-model="next_location">
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="field">
            <div class="label">Itemnr.</div>
            <div class="control">
              <textarea v-model="item_ids" rows="10" class="textarea" type="number" placeholder="Skriv inn itemnr som skal flyttes. Separer med mellomrom eller linje"></textarea>
            </div>
          </div>
        </div>
      </div>
      <button class="button is-success" @click.prevent="handleMove">Flytt</button>
    </form>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'MoveItems',
  data() {
    return {
      item_ids: '',
      to_location: '',
      next_location: ''
      
    }
  },
  methods: {
    ...mapActions(['moveItem', 'getItems']),
    
    handleMove() {
      let elements = [];
      this.item_ids.split(/\s/).forEach(item_id => {
        if(/^[0-9]+$/.test(item_id)) {
          const element = {
            item: item_id,
            to_location: this.to_location,
            next_planned_location: this.next_location
          };
          elements.push(element)
        }
      });
      this.moveItem(elements)
        .then(() => {
          this.item_ids = ''
        })
    }
  },
}
</script>

<style>

</style>