import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import store from './store';

import '@fortawesome/fontawesome-free/css/all.min.css'

import './assets/index.css'


const app = createApp(App);

app.config.devtools = process.env.devtools;

app.use(router);
app.use(store);
app.mount('#app');