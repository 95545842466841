<template>
  <div>
    <nav class="navbar" role="navigation">

      <div class="navbar-brand">
        <a href="/" class="navbar-item is-visible is-active"><p class="has-text-weight-bold is-size-5">Prosjekt Inventar</p></a>
        
        <a @click="burger = !burger" role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" :class="{ 'is-active' : burger}">
          <span aria-hidden="true" :class="{ 'is-active' : burger}"></span>
          <span aria-hidden="true" :class="{ 'is-active' : burger}"></span>
          <span aria-hidden="true" :class="{ 'is-active' : burger}"></span>
        </a>  
      </div>


      <div class="navbar-menu" :class="{ 'is-active' : burger}">
        <div class="navbar-start">
          <a href="/" class="navbar-item">Inventar</a>
          <a href="/move" class="navbar-item">Flytt</a>
          <a href="/import" class="navbar-item">Import</a>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">Avansert</a>
            <div class="navbar-dropdown">
              <a href="/printers" class="navbar-item">Printere</a>
              <a href="/transactions" class="navbar-item">Transaksjoner</a>
              <a href="/replace" class="navbar-item">Erstatt</a>
            </div>
          </div>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <template v-if="$store.state.common.authType">
                <button @click="logout()" class="button is-info">
                  Logg ut
                </button>
              </template>
              <template v-else>
                <a href="/login" class="button is-light">
                  Log in
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      burger: false,
    };
  },
  
  methods: {
    ...mapActions(['logout'])
  },

}
</script>


<style scoped>
  .navbar {
    margin-bottom: 10px;
  }
</style>