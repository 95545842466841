import {createRouter, createWebHistory} from 'vue-router'
import store from '../store'
import MoveView from '../views/MoveView.vue'
import ReplaceView from '../views/ReplaceView.vue'
import HomeView from '../views/HomeView.vue'
import ImportView from '../views/ImportView.vue'
import Login from '../components/Login.vue'
import Printers from '../components/Printers.vue'
import Item from '../components/Item.vue'
import TransactionList from '../components/TransactionList.vue'
import AuthCallback from '../components/AuthCallback.vue'

const routes = [
  {
    path: '',
    name: 'Home',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: '/item/:id',
    name: 'Item',
    component: Item,
    meta: { requiresAuth: true }
  },
  {
    path: '/move',
    name: 'Move',
    component: MoveView,
    meta: { requiresAuth: true },
  },
  {
    path: '/replace',
    name: 'Replace',
    component: ReplaceView,
    meta: { requiresAuth: true },
  },
  {
    path: '/import',
    name: 'Import',
    component: ImportView,
    meta: { requiresAuth: true },
  },
  {
    path: '/printers',
    name: 'Printers',
    component: Printers,
    meta: { requiresAuth: true },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: TransactionList,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    // Used for Microsoft Auth, both login and callback
    path: '/authcallback',
    name: 'Authcallback',
    component: AuthCallback
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(store.state.common.authType) { // if authType, then the user has logged in before. Token refresh etc. will happen on API call
      next()
    } else { // If authType is blank, then user has not logged in
      next('/login')
    }
  } else { // no auth needed for this page
    next()
  }
})

export default router