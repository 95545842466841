<template>
  <div class="box">
    <MoveItem
      :isActive="moveItemModal"
      :items="[item.id]"
      :title="item.name"
      @toggleModal="moveItemModal = false"
      @refresh="refreshData()"
    />


    <div class="level">
      <div class="level-item">
        <h1 class="title">{{ item.name }}</h1>
      </div>
    </div>
    <form @submit.prevent="onSubmit">
      <div class="columns">
        <div class="column is-one-fifth">
          <div class="field">
            <label class="label" for="project">Prosjekt</label>
            <div class="control">
              <input class="input" type="text" name="project" v-model="item.project" required>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label" for="name">Navn</label>
            <div class="control">
              <input class="input" type="text" name="name" v-model="item.name" required>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label" for="description">Beskrivelse</label>
            <div class="control">
              <input class="input" type="text" name="description" v-model="item.description">
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Lokasjon</label>
            <div class="control">
              <input class="input" type="text" id="location" v-model="item.location" readonly>
            </div>
            <p class="help">Bruk flytt knappen for å endre lokasjon</p>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Neste Lokasjon</label>
            <div class="control">
              <input class="input" type="text" id="next-location" v-model="item.next_location" readonly>
            </div>
            <p class="help">Bruk flytt knappen for å endre lokasjon</p>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <div class="label">Notat</div>
            <div class="control">
              <textarea v-model="item.note" rows="10" class="textarea" placeholder="Notater"></textarea>
            </div>
          </div>
        </div>
        <div class="column">
          <button @click="moveItemModal = true" class="button is-success my-2"><span class="icon"><i class="fas fa-truck-moving"></i></span><span>Flytt</span></button>
          <div class="field">
            <label class="checkbox">
              <input v-model="item.label_print" class="checkbox" type="checkbox">
              Send til printer
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input v-model="item.label_printed" class="checkbox" type="checkbox" disabled>
              Label utskrevet {{ item.label_printed_dt ? new Date(item.label_printed_dt).toLocaleString('no-NO') : '' }}
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input v-model="item.active" class="checkbox" type="checkbox">
              Aktiv
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input v-model="item.replaced" class="checkbox" type="checkbox" disabled>
              Erstattet
            </label>
          </div>
          <div>
            Laget av: {{ item.created_by }}
          </div>
          <div>
            Laget: {{ new Date(item.created_dt).toLocaleString('no-NO') }}
          </div>
          <div>
            Sist oppdatert: {{ new Date(item.updated_dt).toLocaleString('no-NO') }}
          </div>
        </div>
      </div>
      <button class="button is-success" type="submit">Lagre</button>
    </form>
    <template v-if="message">
      <div class="notification is-info is-light mt-1">
        {{ message }}
      </div>
    </template>

    <div class="columns">
      <div class="column">
        <div class="level">
          <div class="level-item">
            <h1 class="subtitle">Historikk</h1>
          </div>
        </div>
        <div class="table-container">
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>Dato, Klokkeslett</th>
                <th>Flyttet fra lokasjon</th>
                <th>Flyttet til lokasjon</th>
                <th>Endret neste lokasjon</th>
                <th>Bruker</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="transaction in transactions" :key="transaction.id">
                <tr>
                  <td>{{ new Date(transaction.dt).toLocaleString('no-NO') }}</td> 
                  <td>{{ transaction.from_location }}</td>
                  <td>{{ transaction.to_location }}</td>
                  <td>{{ transaction.next_planned_location }}</td>
                  <td>{{ transaction.created_by }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import MoveItem from './MoveItem.vue';

export default {
  name: 'Item',
  components: {
    MoveItem,
  },
  data() {
    return{
      message: '',
      item: {},
      transactions: [],

      moveItemModal: false,
    }
  },
  computed: {

  },
  props: {
    itemId: Number,
  },

  methods: {
    ...mapActions(['updateItem', 'getItem', 'getItemTransactions']),
    ...mapGetters(['currentItem']),
    ...mapMutations(['setItem']),

    onSubmit() {
      this.updateItem(this.item)
        .then(() => this.getItem(this.$route.params.id))
      this.message = 'Oppdaterte: ' + this.item.name
      setTimeout(() => this.message = '', 2000)
    },

    async refreshData() {
      this.getItem(this.$route.params.id)
        .then((data) => this.item = data)
      this.getItemTransactions(this.$route.params.id)
        .then((data) => this.transactions = data)
    },
  },
  async mounted() {
    this.refreshData()
  },
}
</script>

<style>

</style>