<template >

  <div class="modal" tabindex="0" @keydown.esc="$emit('toggleModal')" :class="{ 'is-active' : isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">

        <div class="box">
          <div class="level">
            <div class="level-item">
              <h1 class="title">Flytt {{ title }}</h1>
            </div>
          </div>

          <form>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Flytt til lokasjon</label>
                  <div class="control">
                    <input type="text" class="input" v-model="to_location">
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="label">Neste planlagt lokasjon</label>
                  <div class="control">
                    <input type="text" class="input" v-model="next_location">
                  </div>
                </div>
              </div>
            </div>

            <button class="button is-success" @click.prevent="handleMove">Flytt</button>
          </form>

        </div>
        <button @click="$emit('toggleModal')" class="button is-danger">Lukk</button>

        
      </section>
    </div>
      <button @click="$emit('toggleModal')" class="modal-close is-large"></button>
  </div>

</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'MoveItems',
  data() {
    return {
      item_ids: '',
      to_location: '',
      next_location: ''
    }
  },
  props: {
    isActive: Boolean,
    title: String,
    items: Array,
  },
  emits: [
    'toggleModal',
    'refresh',
  ],

  methods: {
    ...mapActions(['moveItem']),
    
    handleMove() {
      let transactions = [];
      this.items.forEach(item_id => {
          const transaction = {
            item: item_id,
            to_location: this.to_location,
            next_planned_location: this.next_location
          };
          transactions.push(transaction)
      });

      this.moveItem(transactions)
        .then(() => {
          this.to_location = ''
          this.next_location = ''
          this.$emit('toggleModal')
          this.$emit('refresh')
        })

    }

  },
}
</script>

<style>

</style>