<template>
  <div class="modal" tabindex="0" @keydown.esc="$emit('toggleModal')" :class="{ 'is-active' : isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">

        <div class="box">
          <div class="level">
            <div class="level-item">
              <h1 class="title">Nytt item</h1>
            </div>
          </div>
          <form @submit.prevent="onSubmit">
            <div class="columns">
              <div class="column is-one-fifth">
                <div class="field">
                  <label class="label" for="project">Prosjekt</label>
                  <div class="control">
                    <input class="input" type="text" name="project" v-model="item.project" required>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label" for="name">Navn</label>
                  <div class="control">
                    <input class="input" type="text" name="name" v-model="item.name" required>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label" for="description">Beskrivelse</label>
                  <div class="control">
                    <input class="input" type="text" name="description" v-model="item.description">
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Lokasjon</label>
                  <div class="control">
                    <input class="input" type="text" id="location" v-model="item.location">
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Neste Lokasjon</label>
                  <div class="control">
                    <input class="input" type="text" id="next-location" v-model="item.next_location">
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <div class="label">Notat</div>
                  <div class="control">
                    <textarea v-model="item.note" rows="3" class="textarea" placeholder="Notater"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="checkbox">
                    <input v-model="item.label_print" class="checkbox" type="checkbox">
                    Send til printer
                  </label>
                </div>
              </div>
            </div>
            <button class="button is-success" type="submit">Legg til</button>
          </form>
          <template v-if="message">
            <div class="notification is-info is-light mt-1">
              {{ message }}
            </div>
          </template>
        </div>

        <button @click="$emit('toggleModal')" class="button is-danger">Lukk</button>
      </section>
    </div>
      <button @click="$emit('toggleModal')" class="modal-close is-large"></button>
  </div>


</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'NewItem',
  data() {
    return{
      item: {
        name: '',
        project: '',
        description: '',
        location: '',
        next_location: '',
        note: '',
        label_print: false
      },
      message: '',
      
    }
  },

  props: {
    isActive: Boolean,
  },  

  emits: [
    'toggleModal',
    'refresh',
  ],

  methods: {
    ...mapActions(['addItem', 'getItems']),
    onSubmit() {
      this.addItem(this.item)
        .then().then(() => {
          this.$emit('refresh')
          this.message = 'La til: ' + this.item.name
          this.item.name = ''
          this.item.description = ''
          this.item.note = ''
          setTimeout(() => this.message = '', 2000)
        })
    }
  }
}
</script>

<style>

</style>