<template >

  <div class="modal" tabindex="0" @keydown.esc="$emit('toggleModal')" :class="{ 'is-active' : isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">

        <div class="box">
          <div class="level">
            <div class="level-item">
              <h1 class="title">Erstatt {{ title }}</h1>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="block">
                Dette vil lage en kopi med ny id nr. Navn på gammel item vil få 'OLD-' i starten.
                Gi lokasjon og neste lokasjon til erstatningen.
              </div>
            </div>
          </div>
          <form>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Lokasjon</label>
                  <div class="control">
                    <input type="text" class="input" v-model="location">
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="label">Neste lokasjon</label>
                  <div class="control">
                    <input type="text" class="input" v-model="next_location">
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="checkbox">
                    <input v-model="label_print" class="checkbox" type="checkbox">
                    Send til printer
                  </label>
                </div>
                <div class="field">
                  <label class="checkbox">
                    <input v-model="active" class="checkbox" type="checkbox">
                    Gammel item aktivt status
                  </label>
                </div>
              </div>
            </div>

            <button class="button is-success" @click.prevent="handleReplace">Erstatt</button>
          </form>

        </div>
        <button @click="$emit('toggleModal')" class="button is-danger">Lukk</button>

        
      </section>
    </div>
      <button @click="$emit('toggleModal')" class="modal-close is-large"></button>
  </div>

</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ReplaceItems',
  data() {
    return {
      item_ids: '',
      location: '',
      next_location: '',
      label_print: true,
      active: true
    }
  },
  props: {
    isActive: Boolean,
    title: String,
    items: Array,
  },
  emits: [
    'toggleModal',
    'refresh',
  ],

  methods: {
    ...mapActions(['replaceItem']),
    
    handleReplace() {
      const options = {
          location: this.location,
          next_location: this.next_location,
          label_print: this.label_print,
          active: this.active
      }

      this.items.forEach(item_id => {
        this.replaceItem({id: item_id, options: options})
      });

      this.to_location = ''
      this.next_location = ''
      this.$emit('toggleModal')
      this.$emit('refresh')

    }

  },
}
</script>

<style>

</style>