<template>
  <div class="">redirecting</div>
</template>

<script>
export default {
  name: 'AuthCallback',
  methods: {
  },

  async mounted() {
    await this.$store.dispatch('AzureAuthentication')
    const isAuthenticated = await this.$store.dispatch('checkAuth')
    if (isAuthenticated) this.$router.replace('/');
  }
}
</script>

<style>

</style>