import { createStore } from 'vuex'
import common from './modules/common'
import items from './modules/items'
import printers from './modules/printers'


export default createStore({
  modules: {
    common,
    items,
    printers,
  }  
})