<template>
  <div class="box">
    <div class="level">
      <div class="level-item">
        <h1 class="title">Erstatt</h1>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="block">
          Scan itemene du ønsker å erstatte. Dette vil lage en kopi med ny id nr. Navn på gammel item vil få 'OLD-' i starten.
          Gi lokasjon og neste lokasjon til erstatningen.
          </div>
      </div>
    </div>
    <form>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Lokasjon til erstatning</label>
            <div class="control">
              <input type="text" class="input" v-model="location">
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Neste planlagt lokasjon til erstatning</label>
            <div class="control">
              <input type="text" class="input" v-model="next_location">
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="checkbox">
              <input v-model="label_print" class="checkbox" type="checkbox">
              Send til printer
            </label>
          </div>
          <div class="field">
            <label class="checkbox">
              <input v-model="active" class="checkbox" type="checkbox">
              Gammel item aktivt status
            </label>
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="field">
            <div class="label">Itemnr.</div>
            <div class="control">
              <textarea v-model="item_ids" rows="10" class="textarea" type="number" placeholder="Skriv inn itemnr som skal erstattes. Separer med mellomrom eller linje"></textarea>
            </div>
          </div>
        </div>
      </div>
      <button class="button is-success" @click.prevent="handleReplace">Erstatt</button>
    </form>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ReplaceItems',
  data() {
    return {
      item_ids: '',
      location: '',
      next_location: '',
      label_print: true,
      active: true
    }
  },
  methods: {
    ...mapActions(['replaceItem']),
    
    handleReplace() {
      const options = {
          location: this.location,
          next_location: this.next_location,
          label_print: this.label_print,
          active: this.active
      }
      this.item_ids.split(/\s/).forEach(item_id => {
        if(/^[0-9]+$/.test(item_id)) {
          this.replaceItem({id: item_id, options: options})
        }
      });
      this.item_ids = ''
    },
  },
}
</script>

<style>

</style>