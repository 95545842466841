<template>
  <div class="box">
    <MoveItem
      :isActive="moveItemModal"
      :items="moveItemModalItems"
      :title="moveItemModalTitle"
      @toggleModal="moveItemModal = !moveItemModal"
      @refresh="refreshData(); selected=[]"
    />

    <NewItem
      :isActive="newItemModal"
      @toggleModal="newItemModal = !newItemModal"
      @refresh="refreshData()"
    />

    <ReplaceItem
      :isActive="replaceItemModal"
      :items="replaceItemModalItems"
      :title="replaceItemModalTitle"
      @toggleModal="replaceItemModal = !replaceItemModal"
      @refresh="refreshData(); selected=[]"
    />


    <div class="columns is-centered">
      <div class="column is-one-third is-flex">
          <div class="field has-addons is-pulled-left">
            <p class="control">
              <input class="input" type="text" placeholder="Søk" v-model="search" @keyup.enter="searchOrFilter()">
            </p>
            <p class="control">
              <button @click="searchOrFilter()" class="button is-success">
                Søk
              </button>
            </p>
          </div>
      </div>
      <div class="column is-flex is-justify-content-center">
        <h1 class="title has-text-centered">Inventar</h1>
      </div>
      <div class="column is-one-third is-flex is-justify-content-flex-end">
          <button class="button is-success mx-1" @click="newItemModal = !newItemModal" ><span class="icon"><i class="fas fa-plus"></i></span><span>Nytt item</span></button>
          <button class="button is-link mx-1" @click="exportData(allItems)"><span class="icon"><i class="fas fa-file-export"></i></span><span>Exporter</span></button>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="select">
          <select @change="searchOrFilter()" v-model="filter.label_printed">
            <option value="">Alle</option>
            <option value="true">Kun utskrevne</option>
            <option value="false">Ikke utskrevne</option>
          </select>
        </div>
        <div class="select">
          <select @change="searchOrFilter()" v-model="limit">
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
      <div class="column is-flex is-justify-content-flex-end">
        <div class="buttons">
          <button @click="openMoveItemModal(selected, (selected.length + ' items'))" class="button is-success mx-1"  :disabled="selected.length == 0"><span class="icon"><i class="fas fa-truck-moving"></i></span><span>Flytt</span></button>
          <button @click="printRows" class="button mx-1 is-success" :disabled="selected.length == 0"><span class="icon"><i class="fas fa-print"></i></span><span>Skriv ut</span></button>
          <button @click="deleteItems()" class="button mx-1 is-danger" :disabled="selected.length == 0"><span class="icon"><i class="fas fa-trash-alt"></i></span><span>Slett</span></button>
          <button @click="openReplaceItemModal(selected, (selected.length + ' items'))" class="button mx-1 is-danger" :disabled="selected.length == 0"><span class="icon"><i class="fas fa-recycle"></i></span><span>Erstatt</span></button>
          <button @click="clearPrinterQue()" class="button mx-1 is-danger"><span class="icon fa-stack"><i class="fas fa-print fa-stack-1x fa-inverse"><i class="fas fa-slash fa-stack-1x"></i></i></span><span>Tøm printer kø</span></button>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="block">
          <progress v-if="isLoading" class="progress is-small is-primary" max="100"></progress>
          <table class="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th width="80px">
                  <span @click="selectAll()" class="icon is-pulled-left is-clickable" title="Velg alle" ><i class="fas fa-check"></i></span>
                  <span @click="ordering === 'id' ? ordering = '-id' : ordering = 'id', refreshData()" class="is-clickable">id</span>
                  <span class="icon is-pulled-right"><i class="fas" :class="{'fa-arrow-up' : ordering === 'id', 'fa-arrow-down' : ordering === '-id'}"></i></span>
                </th>
                <th width="5%">Prosjekt</th>
                <th width="15%">
                  <span @click="ordering === 'name' ? ordering = '-name' : ordering = 'name', refreshData()" class="is-clickable">Navn</span>
                  <span class="icon is-pulled-right"><i class="fas" :class="{'fa-arrow-up' : ordering === 'name', 'fa-arrow-down' : ordering === '-name'}"></i></span>
                </th>
                <th>Beskrivelse</th>
                <th>
                  <span @click="ordering === 'location' ? ordering = '-location' : ordering = 'location', refreshData()" class="is-clickable">Lokasjon</span>
                  <span class="icon is-pulled-right"><i class="fas" :class="{'fa-arrow-up' : ordering === 'location', 'fa-arrow-down' : ordering === '-location'}"></i></span>
                </th>
                <th>
                  <span @click="ordering === 'next_location' ? ordering = '-next_location' : ordering = 'next_location', refreshData()" class="is-clickable">Neste Lokasjon</span>
                  <span class="icon is-pulled-right"><i class="fas" :class="{'fa-arrow-up' : ordering === 'next_location', 'fa-arrow-down' : ordering === '-next_location'}"></i></span>
                </th>
              </tr>
              <tr>
                <th></th>
                <th><input @keyup.enter="searchOrFilter()" v-model="filter.project" type="text" class="input is-small p-1"></th>
                <th><input @keyup.enter="searchOrFilter()" v-model="filter.name" type="text" class="input is-small p-1"></th>
                <th><input @keyup.enter="searchOrFilter()" v-model="filter.description" type="text" class="input is-small p-1"></th>
                <th><input @keyup.enter="searchOrFilter()" v-model="filter.location" type="text" class="input is-small p-1"></th>
                <th><input @keyup.enter="searchOrFilter()" v-model="filter.next_location" type="text" class="input is-small p-1"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in allItems" :key="item.id">
                <tr @mouseover="rowHover = item.id" @mouseleave="rowHover = -1">
                  <td>
                    <div class="is-pulled-left pr-2"><input type="checkbox" class="checkbox" v-model="selected" :value="item.id" number></div>
                    {{ item.id }}
                  </td>
                  <td>{{ item.project }}</td> 
                  <td>{{ item.name }}</td>
                  <td>
                    <span>{{ item.description }}</span>
                    <div class="dropdown is-hoverable is-right is-pulled-right is-relative" :class="{'is-invisible' : !item.note}">
                      <div class="dropdown-trigger">
                        <span class="icon"><i class="far fa-sticky-note"></i></span>
                      </div>
                      <div class="dropdown-menu">
                        <div class="dropdown-content">
                          <div class="dropdown-item">
                            <p>{{ item.note }}</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </td>
                  <td>{{ item.location }}</td>
                  <td>
                    <span>{{ item.next_location }}</span>
                    <div class="is-pulled-right is-relative	" :class="{'is-invisible' : rowHover !== item.id}">
                      <span @click="openMoveItemModal([item.id], item.name)" class="icon is-clickable mx-2" title="Flytt item"><i class="fas fa-truck-moving"></i></span>
                      <span @click="handlePrintItem(item.id)" class="icon mx-2" :class="{'is-disabled-icon' : item.label_print, 'is-clickable' : !item.label_print}" title="Skriv ut merkelapp"><i class="fas fa-print"></i></span>
                      <span @click="selectRow(item.id)" class="icon is-clickable" title="Vis mer"><i class="fas fa-ellipsis-v"></i></span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="block">
          <nav class="pagination" role="navigation" aria-label="pagination">
            <button @click="page--; refreshData()" class="pagination-previous" title="Forrige side" :disabled="page == 1">Forrige</button>
            <button @click="page++; refreshData() " class="pagination-next" title="Neste side" :disabled="page == numPages">Neste</button>
            <ul class="pagination-list">
              <li v-for="n in pageList" :key="n">
                <button @click="page = n; refreshData()" class="pagination-link" :class="{ 'is-current': page == n }" aria-label="Page 1" aria-current="page">{{ n }}</button>
              </li>
              
              <li><span class="pagination-ellipsis">Antall: {{ $store.state.items.itemCount }}</span></li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapGetters, mapActions } from 'vuex';
import MoveItem from './MoveItem.vue';
import NewItem from './NewItem.vue';
import ReplaceItem from './ReplaceItem.vue';

import XLSX from 'xlsx';


export default {
  name: 'ItemList',
  components: {
    MoveItem,
    NewItem,
    ReplaceItem,
  },

  data() {
    return {
      search: '',
      page: 1,
      limit: 50,
      rowHover: -1,
      selected: [],
      filter: {
        project: '',
        name: '',
        description: '',
        location: '',
        next_location: '',
        label_printed: '',
      },
      ordering: '',

      isLoading: false,

      moveItemModalItems: [],
      moveItemModalTitle: '',
      moveItemModal: false,

      replaceItemModalItems: [],
      replaceItemModalTitle: '',
      replaceItemModal: false,

      newItemModal: false,


    }
  },
  computed: {
    ...mapGetters(['allItems']),

    numPages() {
      return Math.ceil(this.$store.state.items.itemCount / this.limit)
    },

    pageList() {
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
      const fp = 1
      const lp = Math.ceil(this.$store.state.items.itemCount / this.limit)
      const pl = range(Math.max(fp + 1, this.page - 4), Math.min(lp, Math.max(this.page + 4, 10)), 1)
      pl.unshift(fp)
      if (lp != pl[pl.length -1]) {pl.push(lp)}

      return pl
    },

    offset() {return (this.page-1) * this.limit},

  },

  methods: {
    ...mapActions(['getItems', 'deleteItem', 'replaceItem', 'printItem', 'clearPrinterQue']),
    selectRow(id) {
      this.$router.push(
        {
          name: 'Item',
          params: { id: id }
        })
    },
    exportData(data) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'inventory');
      XLSX.writeFile(wb, 'out.xlsx');
    },

    openMoveItemModal(items, title) {
      this.moveItemModalItems = items
      this.moveItemModalTitle = title
      this.moveItemModal = true
    },

    openReplaceItemModal(items, title) {
      this.replaceItemModalItems = items
      this.replaceItemModalTitle = title
      this.replaceItemModal = true
    },

    async refreshData() {
      this.isLoading = true
      this.getItems({
        limit: this.limit,
        offset: this.offset,
        search: this.search,
        project: this.filter.project,
        name: this.filter.name,
        description: this.filter.description,
        location: this.filter.location,
        next_location: this.filter.next_location,
        label_printed: this.filter.label_printed,
        ordering: this.ordering,
        }).then(() => this.isLoading = false)
    },

    async deleteItems() {
      if (confirm(`Vil du slette ${this.selected.length} rader?`)) {
        this.selected.forEach((id, idx, array) => {
          this.deleteItem(id).then((status) => {
            if (idx === array.length -1 && status) {
              this.selected = []
              this.refreshData()
              }
          })
        })
      }
    },

    handlePrintItem(id) {
      this.printItem(id).then(() => this.refreshData())
    },

    printRows() {
      this.selected.forEach((el) => {this.printItem(el)})
      this.selected = []
      this.refreshData()
    },

    searchOrFilter() {
      // Reset page to 1 before refreshing data
      this.page = 1
      this.refreshData()
    },

    selectAll() {
        if (this.selected.length == 0) {
          this.allItems.forEach(item => {
            this.selected.push(item.id)
          })
        } else {
          this.selected = []
        }
    },

  },
  async mounted() {
    this.refreshData()
  }
}
</script>

<style scoped>

.dropdown {
  z-index:initial;
}

.is-disabled-icon {
  opacity: 70%;
}
</style>