<template>
  <div class="box" @drop.prevent="dropfile" @dragover.prevent>
    <div class="columns is-centered">
      <div class="column is-6 ">
        <div class="card has-background-info-light dropzone">
          <div class="card-content">
            <p class="title">Slipp fil her eller velg fil</p>
            <div class="file">
              <label class="file-label">
                <input @change.prevent="importWorkbook($event.target.files[0])" class="file-input" type="file">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">
                    Velg fil
                  </span>
                </span>
              </label>
            </div>
            <progress v-if="isLoading" class="progress is-small is-primary" max="100"></progress>
            <template v-if="message">
              <div class="notification is-info is-light mt-1">
                {{ message }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <button @click="handleExport()" class="button is-link"><span class="icon"><i class="fas fa-file-export"></i></span><span>Exporter alt</span></button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import XLSX from 'xlsx';
export default {
  name: 'Import',
  data() {
    return {
      isLoading: false,
      message: '',
    }
  },
  computed: {
        ...mapGetters(['allItems']),
  },
  methods: {
    ...mapActions(['addItem', 'getItems']),

    dropfile(e) {
      e.stopPropagation(); e.preventDefault();
      const files = e.dataTransfer.files
      this.importWorkbook(files[0])
    },

    importWorkbook(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.isLoading = true
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'});

        let result = [];
        const sheetName = workbook.SheetNames[0]
        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header:2});
        if(roa.length) result= roa;
        this.addItem(result)
            .then((res) => {
            this.isLoading = false
            console.log(res)
            this.message = `Behandlet ${roa.length} linjer, importerte ${res.length} nye`
            setTimeout(() => this.message = '', 10000)
          })
        };
      reader.readAsArrayBuffer(file);
    },

    async handleExport() {
      this.isLoading = true
      this.getItems()
        .then(() => {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(this.allItems);
          XLSX.utils.book_append_sheet(wb, ws, 'inventory');
          XLSX.writeFile(wb, 'out.xlsx');
          
          this.isLoading = false
        })
    },

  }

}
</script>

<style>


</style>