<template>
  <div>
    <ItemList />
  </div>
</template>

<script>
import ItemList from '../components/ItemList.vue'


export default {
  name: 'HomeView',
  data() {return {

  }},
  components : {
    ItemList,
  },
}
</script>

<style>

</style>