<template>
  <div>
    <ReplaceItems />
  </div>
</template>

<script>
import ReplaceItems from '../components/ReplaceItems.vue'
export default {
  name: 'ReplaceView',
    components: {
      ReplaceItems
  },
}
</script>