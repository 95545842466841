<template>
  <Import />
</template>

<script>
import Import from '../components/Import.vue'
export default {
  name: 'ImportView',
  components: {
    Import,
  }
}
</script>

<style>

</style>